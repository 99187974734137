import { Component, AfterViewInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import * as firebase from 'firebase/analytics'

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements AfterViewInit{

  constructor(
    private title: Title,
    private meta: Meta
  ) {
    // this.meta.addTags([
    //   { name: 'eduku io', content: `eduku landing page`},
    //   { name: 'eduku', content: `eduku`}
    // ])
    title.setTitle('Eduku')
  }

  ngAfterViewInit(): void {
    const analytic = firebase.getAnalytics()
    firebase.logEvent(analytic, 'eventname', {
      content_type: 'visitor',
    })
  }
}
